import { apiUrls } from "../../common/apiUrls";
import { postUrl } from "../../common/feedbackApi";
import { messages } from "../../utils/constants";
import { attemptEmailSignup } from "../LandingPage/Auth/Auth.actions";

export const attemptSignup = async (data) => {
  try {
    const resp = postUrl(apiUrls.emailPasswordSignup, data, false);
    console.log(resp);
  } catch (err) {
    return { success: false };
  }
};

export const completeSignup = async (token) => {
  try {
    const resp = await postUrl(apiUrls.autoLogin(token), {}, false);
    console.log(resp);
    if (!resp) {
      throw new Error("Something went wrong!");
    }
    if (
      resp.success === false &&
      resp.status === 400 &&
      resp.data?.detail === messages.TOKEN_EXPIRED
    ) {
      return {
        success: false,
        message:
          "Looks like the verification link has expired! Click on Resend Verification button to proceed.",
        status: resp.status,
      };
    } else if (
      resp.success === true &&
      resp.data?.message === messages.lOGIN_SUCCESS &&
      resp.data?.access
    ) {
      localStorage.clear();
      localStorage.setItem("access_token", resp.data?.access);
      localStorage.setItem("user_id", resp.data?.user_id);
      return {
        success: true,
        message: "Your verification has been completed!",
        status: resp.status,
      };
    } else if (
      resp.status === 400 &&
      resp.data.detail === messages.USED_TOKEN
    ) {
      return {
        success: false,
        message: "Looks like the verification link is not valid!",
        status: resp.status,
      };
    } else if (resp.status === 409) {
      return {
        success: false,
        message:
          "Looks like you have been already verified! Please Login and Collect those stars!",
        status: resp.status,
      };
    } else {
      return {
        success: false,
        message:
          "Unable to verify! Let's give another try. Click on resend verification button.",
        status: resp.status,
      };
    }
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message:
        "Unable to verify! Let's give another try. Click on resend verification button.",
      status: resp.status,
    };
  }
};

export const resendVerificationLink = async (email) => {
  try {
    const resp = await attemptEmailSignup({ email });
    if (
      resp.data?.message === messages.ACCOUNT_CONFIRMATION_EMAIL_SENT &&
      resp.status === 201
    ) {
      return {
        success: true,
        status: resp.status,
      };
    } else if (resp && resp?.emailAlreadyExistsError) {
      return {
        emailAlreadyExistsError: true,
        success: false,
        message:
          "Looks like you have been already verified! Please Login and Collect those stars!",
        status: resp.status,
      };
    }
    return {
      success: false,
      message:
        "Unable to verify! Let's give another try. Click on resend verification button.",
      status: resp.status,
    };
  } catch (err) {
    return {
      success: false,
      message:
        "Unable to verify! Let's give another try. Click on resend verification button.",
      status: resp.status,
    };
  }
};
