export const getBaseUrl = () => {
  return process.env.REACT_APP_FEEDBACK_API_BASE_URL_PROD;
};

export const apiBaseUrl = {
  baseUrl: process.env.REACT_APP_QR_PRO_STUDIO_API_BASE_URL,
};

export const apiUrls = {
  loginMobileNumber: "/mobile/add",
  addBusinessDetails: "/name/add",
  verifyOtp: "/verify/otp",
  googleAuth: "/google-auth",
  qrcodes: "/review-profile",
  getAndFilterReviewsOld: (fromDate, toDate) =>
    `/filter/review/details?from_date=${fromDate}&to_date=${toDate}`,
  getUserInformation: "/user/profile",
  updateProfileOld: "/profile/update",
  // -------------- V2 API'S START FROM HERE ------------------
  verifyUser: "/user",
  emailLogin: "/login",
  verifyMobileOtp: "/verify-otp",
  addOrganization: "/org",
  userDetails: (user_id) => `/user/${user_id}`,
  getAndFilterReviews: (fromDate, toDate) =>
    `/customer-review-response?from_date=${fromDate}&to_date=${toDate}`,
  // updateProfile: (org_id) => `/profile/${org_id}`,
  updateProfile: "/profile",
  googleOAuth: "/google-oauth",
  emailPasswordSignup: "/signup",
  emailPasswordReset: "/password-reset",
  autoLogin: (token) => `/login?token=${token}`
};
