import React from "react";
import "./index.css";
import Logo from "../../components/AppIcons/AppLogo";
import { Link } from "react-router-dom";
import Button from "../../components/Button/ButtonV2";
import FormComponent from "../../components/FormComponent/FormComponent";
import { emailPasswordSignup } from "./Signup.constants";
import { routes } from "../../common/routeUrls";
import { useSignUp } from "./Signup.hook";

function Signup() {

  const {formData, handleFormChange, onSubmit, loading, contextHolder} = useSignUp();

  return (
    <>
    {contextHolder}
      {/* <header className="container header-container d-flex align-items-center  justify-content-between">
        <div className="d-flex align-items-center">
          <Link to={"/"}>
            <div className="d-flex align-items-center me-4">
              <div className="logo-wrapper me-3">
                <Logo />
              </div>
            </div>
          </Link>
          <Link to={"/"} className="nav-item">
            Home
          </Link>
          <Link to={"/#pricing"} className="nav-item">
            Pricing
          </Link>
        </div>
        <Link to={"/?login=true"} className="m-0">
          <Button text="Login" className="col-1 w-100 btn-sm-login" />
        </Link>
      </header> */}
      <div className="container d-flex flex-column align-items-center justify-content-center mt-2 content-container">
        <div className="logo-wrapper mb-3 mt-5">
          <Logo />
        </div>
        <p className="create-an-account mb-2">Create an account</p>
        <p className="start-free">Start your 3-months free trial.</p>
        <FormComponent
          styleClass="d-flex flex-column align-items-center signup-inputs-container"
          model={emailPasswordSignup}
          showSubmit={true}
          submitButtonText="Get Started"
          buttonContainerClass={"col-md-4 col-sm-12"}
          onChange={handleFormChange}
          formValue={formData?.signupData}
          formName={"signupData"}
          onSubmit={onSubmit}
          loading={loading}
        />
        <p className="already-have-account mt-3">
          Already have an account? <a href="/?login=true">Log in</a>
        </p>
        {/* <div className="d-flex flex-row justify-content-around col-12">
          <Link to={"/"} className="nav-item-sm">
            Home
          </Link>
          <Link to={"/pricing"} className="nav-item-sm">
            Pricing
          </Link>
          <Link to={`/#${routes.howItWorks}`} className="nav-item-sm">
            How it works
          </Link>
        </div> */}
      </div>
    </>
  );
}

export default Signup;
