import { useEffect, useState } from "react";
import { useOnFormChange } from "../../common/hooks/useOnFormChange";
import { validateForm } from "../../utils/validators";
import { emailPasswordSignup } from "./Signup.constants";
import { attemptSignup } from "./Signup.actions";
import { attemptEmailSignup } from "../LandingPage/Auth/Auth.actions";
import { messages } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { routes } from "../../common/routeUrls";
import { message } from "antd";
import { identifiers } from "../../utils/identifiers";

export const useSignUp = () => {
  const { formData, handleFormChange, updateForm } = useOnFormChange();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    document.title = "Signup for free - QR Pro Studio";
  }, []);

  const onSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const validatedFields = validateForm(
      emailPasswordSignup,
      formData.signupData
    );
    updateForm({ signupData: validatedFields.newForm });
    if (!validatedFields.isValid) {
      return;
    }
    setLoading(true);
    const resp = await attemptEmailSignup(formData.signupData);
    if (
      resp.data?.message === messages.SIGNUP_COMPLETED &&
      resp.status === 201
    ) {
      localStorage.clear();
      localStorage.setItem("access_token", resp.data?.access);
      localStorage.setItem("user_id", resp.data.user_id);
      localStorage.setItem(identifiers.is_new_user, true);
      navigate(routes.qrCodes);
    } else if (resp && resp?.emailAlreadyExistsError) {
      updateForm({
        signupData: {
          ...validatedFields.newForm,
          emailError: "A business with this email already exists!",
        },
      });
    } else {
      messageApi.open({
        type: "error",
        content: "Looks like something went wrong! Try again.",
      });
    }
    if (!resp.success) {
      scrollTo(0, 0);
    }
    setLoading(false);
  };

  return {
    formData,
    handleFormChange,
    onSubmit,
    loading,
    contextHolder,
  };
};
